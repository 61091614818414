import { request, noTimeOutReq } from '@/utils/request.js'

//获取个人阅卷记录
export function getPersonalRecordApi (examId) {
  return request({
    url: `/exam/score/${examId}/count/get`,
    method: 'post',
  })
}


//获取考试信息
export function getExamInfoApi (examId) {
  return request({
    url: `/exam/score/${examId}/examInfo/get`,
    method: 'post'
  })
}

//获取阅卷教师信息
export function getMarkTeacherApi (examId) {
  return request({
    url: `/exam/score/${examId}/markTeacher/get`,
    method: 'post'
  })
}

//获取教师阅卷考试题目
export function getQuestionListApi (examId) {
  return request({
    url: `/exam/score/${examId}/question/list`,
    method: 'post'
  })
}

//获取个人阅卷统计（组长会返回组员统计）
export function getPersonalCountListApi (examId) {
  return request({
    url: `/exam/score/${examId}/personal/count/list`,
    method: 'post'
  })
}

//获取评卷记录查询参数
export function getMarkRecordResultParamApi (examId) {
  return request({
    url: `/exam/score/${examId}/query/get`,
    method: 'post',
  })
}

//根据考试id获取阅卷规则信息
export function getMarkRuleInfoApi (data) {
  return request({
    url: `/exam/score/admin/mark/rule/get`,
    method: 'post',
    data: data
  })
}

//获取个人阅卷记录分页
export function getMarkRecordListApi (pageIndex, pageSize, data) {
  return request({
    url: `/exam/score/${pageIndex}/${pageSize}/markRecord/page`,
    method: 'post',
    data: data
  })
}

//阅卷组长获取仲裁卷列表
export function getArbitrationListApi (examId) {
  return request({
    url: `/exam/score/${examId}/arbitration/list`,
    method: 'post'
  })
}

//阅卷组长获取已评试卷列表
export function getAchievementSummaryListApi (pageIndex, pageSize, data) {
  return request({
    url: `/exam/score/${pageIndex}/${pageSize}/achievementSummary/page`,
    method: 'post',
    data: data
  })
}

//组长获取抽查比例
export function getSpotCheckRateApi (examId) {
  return request({
    url: `/exam/score/checkRate/${examId}/get`,
    method: 'post',
  })
}

//【阅卷】组长完成阅卷
export function finishMarkApi (examId) {
  return request({
    url: `/exam/score/${examId}/mark/finish/update`,
    method: 'post',
  })
}

//【阅卷】解除占用
export function removeOccupyApi (examId) {
  return request({
    url: `/exam/score/${examId}/delete`,
    method: 'post',
  })
}


// 阅卷老师统计分页
export function getTeacherCountScore (pageIndex,pageSize,examId,data) {
  return request({
    url: `/exam/score/${examId}/personal/count/page/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
