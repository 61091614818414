<template>
  <div>
    <div>
      <el-button v-throttle type="primary" size="medium" @click="goBack"
        >返回</el-button
      >
    </div>
    <div id="queryForm">
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="姓名" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.studentName"
            placeholder="请输入姓名"
            @input="reloadAchievementConfusionList"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="考号" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.examineeCode"
            placeholder="请输入考号"
            @input="reloadAchievementConfusionList"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="区县" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.orgAreaId"
            placeholder="请选择区县"
            @change="orgAreaChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="area in orgAreaOptions"
              :key="area.orgId"
              :label="area.orgName"
              :value="area.orgId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.orgSchoolId"
            placeholder="请选择学校"
            @change="reloadAchievementConfusionList"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="school in orgSchoolOptions"
              :key="school.orgId"
              :label="school.orgName"
              :value="school.orgId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择科目"
            @change="reloadAchievementConfusionList"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="subjectType in subjectTypeOptions"
              :key="subjectType.value"
              :label="subjectType.label"
              :value="subjectType.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.approveStatus"
            placeholder="请选择状态"
            @change="reloadAchievementConfusionList"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="approveStatus in approveStatusOptions"
              :key="approveStatus.value"
              :label="approveStatus.label"
              :value="approveStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-button v-throttle type="primary" size="medium" @click="inquire"
          >查询</el-button
        >
        <el-button v-throttle size="medium" @click="refresh">重置</el-button>
        <el-button
          v-throttle
          v-if="confusionInfo.markLeader"
          type="primary"
          size="medium"
          @click="evaluation"
          >重新评卷({{ confusionInfo.confusionNum }})</el-button
        >
        <!-- <span v-if="markTeacherInfo && markTeacherInfo.teacherType==3">组长只能评阅本科目的试卷</span> -->
      </el-form>
    </div>

    <div id="table_box">
      <el-table
        v-loading="loading"
        stripe
        border
        ref="userTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          background: '#DFE6EC',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="studentName"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="examineeCode"
          label="考号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="sysOrgAreaName"
          label="区县"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="sysSchoolName"
          label="学校"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="examStartTime"
          label="考试时间"
          show-overflow-tooltip
          :min-width="110"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.examStartTime && scope.row.examEndTime">
              <span v-if="scope.row.examStartTime">
                {{
                  formatDateTime(
                    new Date(scope.row.examStartTime),
                    'yyyy-MM-dd hh:mm:ss'
                  ) + '-'
                }}
              </span>
              <br />
              <span v-if="scope.row.examEndTime">
                {{
                  formatDateTime(
                    new Date(scope.row.examEndTime),
                    'yyyy-MM-dd hh:mm:ss'
                  )
                }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subjectType" label="科目" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.subjectType == 1">物理</span>
            <span v-else-if="scope.row.subjectType == 2">生物</span>
            <span v-else-if="scope.row.subjectType == 3">化学</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="score"
          label="成绩"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="reason"
          label="申请理由"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="gmtPropose"
          label="申请时间"
          show-overflow-tooltip
          :min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gmtPropose">
              {{
                formatDateTime(
                  new Date(scope.row.gmtPropose),
                  'yyyy-MM-dd hh:mm:ss'
                )
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="gmtApprove"
          label="审批时间"
          show-overflow-tooltip
          :min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gmtApprove">
              {{
                formatDateTime(
                  new Date(scope.row.gmtApprove),
                  'yyyy-MM-dd hh:mm:ss'
                )
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="approveStatus"
          label="状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.approveStatus == 1">待审批</span>
            <span v-else-if="scope.row.approveStatus == 2">审批通过</span>
            <span v-else-if="scope.row.approveStatus == 3">审批驳回</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="操作"
          show-overflow-tooltip
          :min-width="180"
        >
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="lookOriginalScore(scope.row)"
              >查看原成绩</el-button
            >
            <el-button
              v-throttle
              v-if="scope.row.markLeader && scope.row.approveStatus == 1"
              size="mini"
              type="primary"
              @click="passApprove(scope.row)"
              >通过</el-button
            >
            <el-button
              v-throttle
              v-if="scope.row.markLeader && scope.row.approveStatus == 1"
              size="mini"
              type="primary"
              @click="rejectApprove(scope.row)"
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div id="pagination_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryPageParam.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="queryPageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryPageParam.totalSize"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { setStore, getStore, removeStore } from '@/utils/token.js'
import {
  getAchievementConfusionListApi,
  getAchievementConfusionCountApi,
  passApproveApi,
  rejectApproveApi,
} from '@/api/score/examObjectionView.js'
import { getMarkTeacherApi } from '@/api/marking/markingDetails.js'
import {
  getOrgAreaListApi,
  getOrgSchoolListApi,
} from '@/api/user/userManage.js'
import { isEmpty } from '@/utils/util'
export default {
  name: 'examObjectionView',
  data() {
    return {
      //路由参数
      urlParam: {
        examId: 0,
        subject: 0,
      },
      loading: false,
      queryParam: {
        approveStatus: '',
        examineeCode: '',
        orgAreaId: '',
        orgSchoolId: '',
        studentName: '',
        subjectType: '',
      },
      queryPageParam: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      tableData: [],
      confusionInfo: {},
      markTeacherInfo: {},
      //筛选
      orgAreaOptions: [],
      orgSchoolOptions: [],
      subjectTypeOptions: [
        { label: '物理', value: 1 },
        { label: '生物', value: 2 },
        { label: '化学', value: 3 },
      ],
      approveStatusOptions: [
        { label: '待审批', value: 1 },
        { label: '审批通过', value: 2 },
        { label: '审批驳回', value: 3 },
      ],
    }
  },
  beforeDestroy() {
    if (this.$route.path !== '/examManage/scoreDetails') {
      removeStore('queryParam')
      removeStore('queryPageParam')
    }
  },
  mounted() {
    this.renderUrlParam()
    if (getStore('queryParam')) {
      this.queryParam = JSON.parse(getStore('queryParam'))
      this.queryPageParam = JSON.parse(getStore('queryPageParam'))
    }
  },
  methods: {
    renderUrlParam() {
      this.urlParam.examId = 0
      this.urlParam.examId = this.$route.query.examId
      this.urlParam.subject = 0
      this.urlParam.subject = this.$route.query.subject
      this.getOrgAreaList()
      this.renderPageData()
    },
    renderPageData() {
      if (isEmpty(this.urlParam.examId)) {
        return
      }
      this.getAchievementConfusionCount()
      this.getAchievementConfusionList()
      // this.getMarkTeacher();
    },
    // 考试异议分页查询
    getAchievementConfusionList() {
      getAchievementConfusionListApi(
        this.queryPageParam.pageIndex,
        this.queryPageParam.pageSize,
        this.urlParam.examId,
        this.queryParam
      ).then((res) => {
        if (res.success) {
          this.tableData = []
          this.tableData = res.data
          this.queryPageParam.totalSize = 0
          this.queryPageParam.totalSize = res.total
        }
      })
    },
    //获取考试待重评数量
    getAchievementConfusionCount() {
      getAchievementConfusionCountApi(this.urlParam.examId).then((res) => {
        if (res.success) {
          this.confusionInfo = {}
          this.confusionInfo = res.data
        } else {
          /* if (res.code != '500') {
            this.msgWarning(res.msg);
          } */
        }
      })
    },
    //获取筛选区县列表
    getOrgAreaList() {
      getOrgAreaListApi()
        .then((res) => {
          if (res.success) {
            this.orgAreaOptions = []
            res.data.childrenOrg.forEach((item) => {
              this.orgAreaOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    getMarkTeacher() {
      getMarkTeacherApi(this.urlParam.examId).then((res) => {
        this.markTeacherInfo = {}
        if (res.success) {
          this.markTeacherInfo = res.data
        }
      })
    },
    orgAreaChange(orgId) {
      this.queryParam.orgSchoolId = ''
      this.getOrgSchoolList(orgId)
      this.reloadAchievementConfusionList()
    },
    //获取筛选学校列表
    getOrgSchoolList(orgId) {
      if (isEmpty(this.queryParam.orgAreaId) || !this.queryParam.orgAreaId) {
        return
      }
      getOrgSchoolListApi(0, 1, orgId)
        .then((res) => {
          if (res.success) {
            this.orgSchoolOptions = []
            this.orgSchoolOptions = res.data.childrenOrg
          }
        })
        .catch(() => {})
    },
    // 分页操作
    handleCurrentChange(val) {
      this.queryPageParam.pageIndex = val
      this.getAchievementConfusionList()
    },
    handleSizeChange(val) {
      this.queryPageParam.pageSize = val
      this.getAchievementConfusionList()
    },
    reloadAchievementConfusionList() {
      this.queryPageParam.pageIndex = 1
      this.getAchievementConfusionList()
    },
    //重新评卷
    evaluation() {
      if (this.confusionInfo.confusionNum == 0) {
        this.msgWarning('没有待重新评卷的考生')
        return
      }
      this.$router.push({
        path: '/examManage/examObjectionMarking',
        query: {
          examId: this.urlParam.examId,
          subject: this.urlParam.subject,
        },
      })
    },
    refresh() {
      this.queryParam.approveStatus = ''
      this.queryParam.subjectType = ''
      this.queryParam.orgSchoolId = ''
      this.queryParam.orgAreaId = ''
      this.queryParam.examineeCode = ''
      this.queryParam.studentName = ''
      this.getAchievementConfusionCount()
      this.reloadAchievementConfusionList()
      // this.getMarkTeacher();
    },
    //
    inquire() {
      this.getAchievementConfusionCount()
      this.reloadAchievementConfusionList()
    },
    //查看原成绩
    lookOriginalScore(row) {
      // 存信息
      setStore('queryParam', JSON.stringify(this.condition))
      setStore('queryPageParam', JSON.stringify(this.queryPageParam))
      this.$router.push({
        path: '/examManage/scoreDetails',
        query: {
          achievementSummaryId: row.achievementSummaryId,
          examId: this.urlParam.examId,
          type: 1,
          subject: this.urlParam.subject,
        },
      })
    },
    //异议审批通过
    passApprove(row) {
      passApproveApi(row.achievementConfusionId).then((res) => {
        if (res.success) {
          this.msgSuccess('异议通过')
          this.getAchievementConfusionCount()
          this.getAchievementConfusionList()
        } else {
          if (res.code != '500') {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    //异议审批驳回
    rejectApprove(row) {
      this.globalMessageBox(` 确定驳回吗？`, '提示', '确定', '取消', 'warning')
        .then(() => {
          rejectApproveApi(row.achievementConfusionId).then((res) => {
            if (res.success) {
              this.msgSuccess('驳回成功')
              this.getAchievementConfusionList()
            } else {
              if (res.code != '500') {
                this.msgWarning(res.msg)
              }
            }
          })
        })
        .catch(() => {})
    },
    goBack() {
      this.$router.push({
        path: '/examManage/examDetails',
        query: {
          examId: this.urlParam.examId,
          subject: this.urlParam.subject,
        },
      })
    },
    /* 时间格式化 */
    formatDateTime(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
  },
}
</script>
<style lang="scss" scoped>
#queryForm,
#table_box,
#pagination_box {
  margin-top: 10px;
}

#queryForm ::v-deep .el-input {
  width: 155px;
}

#queryForm span {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}
</style>
