import { request, noTimeOutReq } from '@/utils/request.js'

//考试异议分页查询
export function getAchievementConfusionListApi (pageIndex, pageSize, examId, data) {
  return request({
    url: `/exam/score/achievement/confusion/${pageIndex}/${pageSize}/${examId}/page`,
    method: 'post',
    data: data
  })
}

//获取考试待重评数量
export function getAchievementConfusionCountApi (examId) {
  return request({
    url: `/exam/score/achievement/confusion/${examId}/count`,
    method: 'post',
  })
}


export function passApproveApi (achievementConfusionId) {
  return request({
    url: `/exam/score/achievement/confusion/${achievementConfusionId}/approve/pass/save`,
    method: 'post',
  })
}

export function rejectApproveApi (achievementConfusionId) {
  return request({
    url: `/exam/score/achievement/confusion/${achievementConfusionId}/approve/reject/save`,
    method: 'post',
  })
}

